.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.item {
    padding: 0px 5px;
}

@media (max-width: 1110px) {
    .container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .item {
        padding: 5px 5px;
    }
}

@media (max-width: 940px) {
    .container {
        grid-template-columns: 1fr 1fr;
    }

    .item {
        padding: 5px 5px;
    }
}

@media (max-width: 600px) {
    .container {
        grid-template-columns: 1fr
    }

    .item {
        padding: 5px 5px;
    }
}